
import { Component, Vue } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL, EventBus } from "../../../config";
import { authHeader } from "../../../services/auth";
// import { PerfectScrollbar } from "vue2-perfect-scrollbar";
// import Notification from "../../../common-components/Notification.vue";
import BrokerLoanList from "../../../common-components/loan-list/BrokerLoanList.vue";

@Component({
  components: {
    // Notification,
    BrokerLoanList,
  },
})
export default class DashBoard extends Vue {
  /* eslint-disable */
  public file: any = null;
  public allLoan: any = [];
  public isOnBoardingCompleted: any = null;
  public brokerName: any = null;
  public documents: any = [];
  public loanId: any = "";
  public uploadPercentage: any = 0;
  public formData: any = new FormData();
  public isUploading = false;
  public skip = 0;
  public totalLength = 0;
  public currentLengthOnScroll = 0;
  public loanpurpose: any = null;
  public priorNotification = false;
  public disclosureData: any = {};
  public borrowerList: any = [];
  public isAllow = "NO";
  public isAllowed = false;
  public borrowerInfo: any = {};
  public isDisabled = false;

  public getXmlFile(event) {
    this.file = event.target.files[0];
  }

  //Uploading xml file & creating new loan.
  public async uploadXml() {
    try {
      let fileType = this.file.name.split(".").pop();
      if (fileType.toLowerCase() != "xml") {
        this.$snotify.error("Invalid File extension.");
        return;
      }
      this.isUploading = true;
      // this.isAllowed = true;
      this.uploadPercentage = 0;
      let formData: FormData = new FormData();
      formData.append("file", this.file);
      formData.append("isAllow", this.isAllow);

      let response = await Axios.post(
        BASE_API_URL + "broker/uploadMismo",
        formData,
        {
          headers: authHeader(),
          onUploadProgress: function (this: any, progressEvent) {
            this.uploadPercentage = Math.round(
              (progressEvent.loaded / progressEvent.total) * 100
            );
          }.bind(this),
        }
      );

      if (response.data && response.data.code == 200) {
        this.isAllowed = false;
        this.$modal.hide("showloan");
        this.$snotify.success(response.data.message);
        EventBus.$emit("refres-loan-list", true);
        this.file = null;
        this.$bvModal.hide("new-loan-modal");

      } else if (response?.data?.code == 401) {
        this.$snotify.info(response.data.message);
      } else if (response?.data?.code == 201) {
        this.$snotify.info(response.data.message);
      } else if (response?.data?.code == "invalid") {
        this.$snotify.info(response.data.message);
      } else if (response?.data?.code == 203) {
        this.borrowerList = response.data.borrowerData;
        this.borrowerInfo = response.data.borrowerNameEmail;
        if (this.borrowerList.length > 0) {
          this.$modal.show("showloan");
        }
      }
      this.uploadPercentage = 0;
      this.isUploading = false;
             this.isDisabled = false;

    } catch (error) {
      console.log(error);
    }
  }

  public async generateFakeData() {
    try {
      const response = await Axios.post(
        BASE_API_URL + "broker/dashboard/generateFakeData",
        {
          loanId: "ssds",
        },
        {
          headers: authHeader(),
        }
      );
      this.allLoan = response.data;
    } catch (error) {
      console.log(error);
    }
  }

  public async fetchBrokerDetail() {
    this.$store.state.tmpcoLoader = true;
    try {
      let response = await Axios.get(
        BASE_API_URL + "broker/dashboard/fetchBrokerDetail",
        {
          headers: authHeader(),
        }
      );

      this.brokerName = response.data.brokerName;
      this.isOnBoardingCompleted = response.data.isOnBoardingCompleted;
      this.isAllow = "NO";
    } catch (error) {
      console.log(error);
    }
    this.$store.state.tmpcoLoader = false;
  }

  public appendData(event, documentId) {
    let files = event.target.files;
    let docDetails: any = {
      loanId: this.loanId,
      documentId: documentId,
    };
    this.formData.append("loanId", this.loanId);
    this.formData.append("documentId", documentId);
    Array.from(files).forEach((f) => {
      this.formData.append("file", f);
    });
  }

  cancelModal() {
    this.isDisabled = true;
    this.$bvModal.hide("new-loan-modal");
    if (this.file) this.file = null;
      
  }

  cancelconfirmationModal() {
    this.$modal.hide("showloan");
    this.fetchBrokerDetail();
    EventBus.$emit("refres-loan-list", true);
  }

  public getTotalLoanLength(data) {
    this.totalLength = data;
  }

  async mounted() {
    await this.fetchBrokerDetail();
    if (!this.isOnBoardingCompleted)
      this.$router.push({
        path: "/vendor-credentials",
        query: { id: this.$store.state.sessionObj.userId },
      });
  }
  /* eslint-disable */
}
